<template>
	<div class="industry-detail">
		<div class="breadcrumb" v-show="!isFullscreen">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>行业信息库</el-breadcrumb-item>
				<el-breadcrumb-item to="/industry/industry-dynamics" v-show="type == 1">建筑行业动态</el-breadcrumb-item>
				<el-breadcrumb-item to="/industry/policy-planning" v-show="type == 2">政策规划</el-breadcrumb-item>
				<el-breadcrumb-item to="/industry/data-report" v-show="type == 3">资料报告库</el-breadcrumb-item>
				<el-breadcrumb-item>{{ getName(type) }}详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="detail-container">
			<div class="fie-title">
				<div class="file-name-box">
					<!-- <div class="file-img"></div> -->
					<el-tooltip effect="light" :content="detailData.industryTitle" placement="bottom">
						<div class="file-name" v-show="type == 1">
							{{ detailData.industryTitle }}
						</div>
					</el-tooltip>
					<el-tooltip effect="light" :content="detailData.policyTitle" placement="bottom">
						<div class="file-name" v-show="type == 2">
							{{ detailData.policyTitle }}
						</div>
					</el-tooltip>

					<el-tooltip effect="light" :content="detailData.datumTitle" placement="bottom">
						<div class="file-name" v-show="type == 3">
							{{ detailData.datumTitle }}
						</div>
					</el-tooltip>
				</div>
				<div class="collect-box" @click="toCollect">
					<span class="iconfont icon-shoucang"
						:style="{ color: detailData.isCollection ? '#fca82f' : '#8E98AE' }"></span>
					<span>{{ detailData.isCollection ? "已" : "" }}收藏</span>
				</div>
				<div class="download-box" @click="toDownload" v-if="downloadButtonShow&&type==1">
					<span class="iconfont icon-download"></span>
					<span>下载</span>
				</div>
				<div class="download-box" @click="toDownload" v-if="downloadButtonShow&&type==2">
					<span class="iconfont icon-download"></span>
					<span>下载</span>
				</div>
				<div class="download-box" @click="click" v-if="fileType == 1 ? true : filePath">
					<span :class="isFullscreen ? 'iconfont icon-quxiaoquanping' : 'iconfont icon-quanping'"></span>
					<span>{{ isFullscreen ? "取消全屏" : "全屏预览" }}</span>
				</div>
			</div>
			<div :class="isFullscreen ? 'full-file-content ql-editor' : 'file-content ql-editor'" v-show="fileType == 1"
				v-html="detailData.detail"></div>
			<div class="content" :class="isFullscreen ? 'full-file-content' : 'file-content'" id="content"
				v-show="fileType != 1" @scroll="scrollEvent">
				<div class="operation" v-show="isFullscreen && fileType != 1">
					<img @dragstart.prevent src="@/assets/img/magnify.png" alt="" @click="toMagnify"
						v-show="scaleNum != 1" />
					<img @dragstart.prevent src="@/assets/img/magnify-stop.png" alt="" v-show="scaleNum == 1"
						style="cursor: not-allowed" />
					<div class="line"></div>
					<img @dragstart.prevent src="@/assets/img/shrink.png" alt="" @click="toShrink"
						v-show="scaleNum != -3" />
					<img @dragstart.prevent src="@/assets/img/shrink-stop.png" alt="" v-show="scaleNum == -3"
						style="cursor: not-allowed" />
					<div class="line"></div>
					<img @dragstart.prevent src="@/assets/img/refresh.png" alt="" @click="toRefresh" />
				</div>
				<!-- <div class="file-content" v-show="fileType != 1"> -->
				<iframe :src="kkFileUrl + previewUrl" v-if="type == 1 && previewUrl && kkFileUrl"
					class="preview-iframe"></iframe>
				<template v-else>
					<pdf v-for="item in numPages" :key="item" :src="filePath" :page="item"
						v-show="filePath && fileType == 2">
					</pdf>
					<div id="wordView" v-show="fileType == 3" v-html="wordHtml" />
				</template>
			</div>
			<!-- <div class="masking-box" v-show="nextShow">
        <div class="text-box" @click="next()">
          <span class="member">继续阅读</span>
        </div>
      </div> -->
		</div>
		<!-- <div class="small-title-box"
			:style="!isFullscreen ? (recommendList.length != 0 ? 'display: block' : 'display: none') : 'display: none'">
			<div class="small-title">推荐阅读</div>
		</div>
		<div class="tab-box"
			:style="!isFullscreen ? (recommendList.length != 0 ? 'display: block' : 'display: none') : 'display: none'">
			<div class="tab-list" v-show="type == 1">
				<div class="list-industry" v-for="(item, index) in recommendList" :key="index" @click="toDetail(1, item)">
					<div class="name-left">
						<div class="tag-icon"></div>
						<el-tooltip effect="light" :content="item.industryTitle" placement="bottom-start">
							<div class="name-box text-ellipsis">
								{{ item.industryTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="code-bottom">
						<div class="serial-code">{{ item.serialCode }}</div>
						<div class="date-right">{{ item.shelvesDate }}</div>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="type == 2">
				<div class="list-item" v-for="(item, index) in recommendList" :key="index" @click="toDetail(2, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.policyTitle" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.policyTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item" v-for="(key, i) in item.policyTag" :key="i">
								{{ key }}
							</div>
						</div>
						<div class="date">{{ item.dispatchDate }}</div>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="type == 3">
				<div class="list-item" v-for="(item, index) in recommendList" :key="index" @click="toDetail(3, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.datumTitle" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.datumTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item" v-for="(key, i) in item.datumTag" :key="i">
								{{ key }}
							</div>
						</div>
						<div class="date">{{ item.shelvesDate }}</div>
					</div>
				</div>
			</div>
		</div> -->
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="true" :showPayBtn="showPayBtn" :text="text" type="行业动态">
		</ToPayOrMember>
	</div>
</template>

<script>
import pdf from "vue-pdf";
import MemberModal from "@/components/MemberModal";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
// import screenfull from "screenfull";
import mammoth from "mammoth";
import { Base64 } from "js-base64";
import ToPayOrMember from "@/components/ToPayOrMember"
import { mapGetters } from "vuex";
export default {
	components: {
		pdf,
		MemberModal,
		ToPayOrMember
	},
	data() {
		return {
			text: "",
			showPayBtn: false,
			id: null,
			fileId: '',
			type: null, // 1 行业动态详情 2 政策规划库详情 3 资料报告库详情
			detailData: {},
			numPages: 0, // 总页数
			times: 1,
			yushu: 1,
			pageArray: [],
			currentPage: 1,
			nextShow: false,
			src: "",
			wordHtml: "",
			fileType: "", // 1 富文本 2 pdf 3 word
			recommendList: [], //推荐阅读
			visible: false,
			isFullscreen: false,
			scaleNum: 1, // 放大缩小基数
			fileExtension: "",
			filePath: "",
			previewUrl: '',
			downloadButtonShow: null,
			localFileUrl:this.localFileUrl
		};
	},
	computed: {
		...mapGetters(['kkFileUrl']),
	},
	mounted() {
		document.getElementById("app").className = "uncopy";
		let type = this.$route.query.type;
		let id = this.$route.query.id;
		let fileId = this.$route.query.fileId;
		//type 1 行业动态详情 2 政策规划库详情 3 资料报告库详情
		if (type == 1) {
			this.showPayBtn = true
			this.getIndustryDynamicInfo(id, fileId);
		} else if (type == 2) {
			this.getPolicyPlanInfo(id);
		} else {
			this.getDatumReportInfo(id);
		}
		this.getRecommendList(type, id);
		this.type = type;
		this.id = id;
		let user = this.$session.getUsers();
		this.memberId = user.memberId;
		this.getDownloadButtonShow();
	},
	methods: {
		// htmlToString(htmlStr) {
		//   return htmlStr.replace(/\n/g, "");
		// },
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode:this.type==1?10031003:10031002,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow == "10011001" ? true : false;
			});
		},
		scrollEvent(e) {
			if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >= e.currentTarget.scrollHeight) {
				//容差：20px
				console.log("滚动到底部");
				if (this.currentPage == this.times - 1) {
					this.nextShow = false;
				}
				if (this.currentPage == this.times) {
					// this.nextShow = false;
					return;
				}
				if ((this.currentPage + 1) * 5 <= this.numPages) {
					for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
						this.pageArray.push(i);
					}
				} else {
					for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
						this.pageArray.push(i);
					}
				}
				this.currentPage++;
			}
		},
		// 点击放大
		toMagnify() {
			let { scaleNum } = this;
			scaleNum += 1;
			this.scaleNum = scaleNum;
			document.querySelector("#content").style.padding = `10px ${300 - (scaleNum - 1) * 50}px`;
		},
		// 点击缩小
		toShrink() {
			let { scaleNum } = this;
			scaleNum -= 1;
			this.scaleNum = scaleNum;
			document.querySelector("#content").style.padding = `10px ${300 - (scaleNum - 1) * 50}px`;
		},
		// 点击刷新
		toRefresh() {
			this.scaleNum = 1;
			document.querySelector("#content").style.padding = "10px 300px";
		},
		// 获取详情名称
		getName(type) {
			if (type == 1) {
				return "建筑行业动态";
			} else if (type == 2) {
				return "政策规划";
			} else {
				return "资料报告库";
			}
		},
		// 点击收藏
		toCollect() {
			let { detailData, type } = this;
			let collectionType = "";
			if (type == 1) {
				collectionType = "60611002";
			} else if (type == 2) {
				collectionType = "60611004";
			} else {
				collectionType = "60611005";
			}
			if (detailData.isCollection == 0) {
				// 收藏
				this.$api.personalCenter
					.collection({
						relationId: this.id,
						collectionType,
					})
					.then(() => {
						this.$message.success("收藏成功");
						this.detailData.isCollection = 1;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 取消收藏
				this.$api.personalCenter
					.cancel({
						relationId: this.id,
						collectionType,
					})
					.then(() => {
						this.$message.success("取消收藏成功");
						this.detailData.isCollection = 0;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 网页全屏方法
		click() {
			// if (!screenfull.isEnabled) {
			//   this.$message({ message: "你的浏览器不支持全屏", type: "warning" });
			//   return false;
			// }
			// screenfull.toggle();
			let { filePath } = this;
			if (filePath && this.type == 1) {
				const fileKey = filePath.substr(filePath.lastIndexOf("/"));
				window.open(this.kkFileUrl + this.previewUrl, fileKey);
			} else {
				if (this.isFullscreen) {
					this.toRefresh();
					this.isFullscreen = !this.isFullscreen;
					document.querySelector(".detail-container").style.width = "1200px";
					document.querySelector(".file-content").style.padding = "10px 200px";
				} else {
					this.isFullscreen = !this.isFullscreen;
					document.querySelector(".detail-container").style.width = "100vw";
				}
			}
		},
		// 获取行业动态详情
		getIndustryDynamicInfo(id, fileId) {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getPdfUrl({
					fileId: fileId
				})
				.then(res => {
					this.localFileUrl=res.msg
					this.previewUrl = "/onlinePreview?url=" + encodeURIComponent(Base64.encode(res.msg)) + '&office.preview.switch.disabled=true';
					this.filePath = res.msg
					// loading.close();
					// 解析 PDF
					// const taskData = pdf.createLoadingTask(res.msg);
					// 拿到 PDF 的页数
					// taskData.promise.then((r) => {
					// 	this.numPages = r.numPages
					// 	this.filePath = res.msg
					// 	this.fileType = 2;
					// })
				}).finally(() => { 
					loading.close();

				});
			this.$api.industry
				.getIndustryDynamicInfo({
					industryDynamicId: id,
					platform: 1,
				})
				.then(res => {
					let data = res.data;
					// this.filePath = res.data.filePath;
					this.fileExtension = res.data.fileExtension;
					if (data.isBanCopy == "10011002") {
						let app = document.getElementById("app");
						app.removeAttribute("class");
					}
					if (data.uploadWay == "60471002") {
						if (data.url == ".pdf") {
							this.fileType = 2;
							this.isShow = true;
						}
						if (data.url == ".docx") {
							this.isDoc = true;
							this.fileType = 3;
						}
					} else {
						// 富文本
						this.fileType = 1;
					}
					this.detailData = data;
				})
				.catch(msg => {
					if (msg.code == '10001111') {
						this.$refs.toPayOrMember.openDialog()
						this.showPayBtn = true
						this.text = msg?.msg
					} else {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => { });
		},
		// 获取政策规划详情
		getPolicyPlanInfo(id) {
			this.$api.industry
				.getPolicyPlanInfo({
					policyPlanId: id,
					platform: 1,
				})
				.then(res => {
					let data = res.data;
					this.filePath = res.data.filePath;
					this.fileExtension = res.data.fileExtension;
					if (data.isBanCopy == "10011002") {
						let app = document.getElementById("app");
						app.removeAttribute("class");
					}
					if (data.uploadWay == "60471002") {
						if (data.url.toLowerCase().indexOf(".pdf") != -1) {
							// pdf文件
							this.fileType = 2;
							const loading = this.$loading({
								lock: true,
								text: "加载中...",
								spinner: "el-icon-loading",
								background: "rgba(255, 255, 255, 0.3)",
								fullscreen: false,
							});
							this.$api.industry
								.getPolicyPlanInfoFile({
									policyPlanId: id,
									platform: 1,
								})
								.then(res => {
									if (res.type == 'application/json') {
										let that = this
										let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
										reader.readAsText(res)
										console.log(res, "json");
										reader.onload = function (result) {
											let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
											loading.close();
											if (resData.code == '10001111') {
												that.$refs.toPayOrMember.openDialog()
												that.showPayBtn = false
												that.text = resData?.msg
											} else {
												that.$message.error(resData?.msg);
											}

										}
									} else {
										this.filePath = this.$utils.getObjectURL(res);
										let src = pdf.createLoadingTask({
											url: this.filePath,
											CMapReaderFactory,
										});
										src.promise.then(pdf => {
											this.numPages = pdf.numPages;
											console.log(this.numPages);
											loading.close();
											// this.times = Number.parseInt(this.numPages / 5);
											// this.yushu = this.numPages % 5;

											// if (this.yushu > 0) {
											// 	this.times++;
											// 	if (this.times == 1) {
											// 		this.nextShow = false;
											// 		for (let i = 1; i <= this.yushu; i++) {
											// 			this.pageArray.push(i);
											// 		}
											// 	} else {
											// 		this.nextShow = true;
											// 		for (let i = 1; i <= 5; i++) {
											// 			this.pageArray.push(i);
											// 		}
											// 	}
											// } else if (this.yushu == 0) {
											// 	if (this.times == 1) {
											// 		this.nextShow = false;
											// 	} else {
											// 		this.nextShow = true;
											// 	}
											// 	for (let i = 1; i <= 5; i++) {
											// 		this.pageArray.push(i);
											// 	}
											// }
											// console.log(this.pageArray);
										});
									}

								}).catch(msg => {
									console.log(msg, "333333");
									if (msg.code == '10001111') {
										this.payVisible = true
										this.showPayBtn = false
										this.text = msg?.msg
									} else {
										this.$message.error(msg?.msg);
									}
								});
						} else {
							// word文档
							const loading = this.$loading({
								lock: true,
								text: "加载中...",
								spinner: "el-icon-loading",
								background: "rgba(255, 255, 255, 0.3)",
								fullscreen: false,
							});
							this.fileType = 3;
							this.$api.industry
								.getPolicyPlanInfoFile({
									policyPlanId: id,
									platform: 1,
								})
								.then(res => {
									if (res.type == 'application/json') {
										let that = this
										let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
										reader.readAsText(res)
										reader.onload = function (result) {
											let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
											loading.close();
											if (resData.code == '10001111') {
												that.$refs.toPayOrMember.openDialog()
												that.showPayBtn = false
												that.text = resData?.msg
											} else {
												that.$message.error(resData?.msg);
											}

										}
									} else {
										loading.close();
										this.src = this.$utils.getObjectURL(res);
										this.reviewWord(data.url, this.src);
									}
								}).catch(msg => {
									if (msg.code == '10001111') {
										this.payVisible = true
										this.showPayBtn = false
										this.text = msg?.msg
									} else {
										this.$message.error(msg?.msg);
									}
								});
						}
					} else {
						// 富文本
						this.fileType = 1;
					}
					this.detailData = data;
				})
				.catch(msg => {
					if (msg.code == '10001111') {
						this.$refs.toPayOrMember.openDialog()
						this.showPayBtn = false
						this.text = msg?.msg
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取资料报告详情
		getDatumReportInfo(id) {
			this.$api.industry
				.getDatumReportInfo({
					datumReportId: id,
					platform: 1,
				})
				.then(res => {
					let data = res.data;
					this.filePath = res.data.filePath;
					this.fileExtension = res.data.fileExtension;
					if (data.isBanCopy == "10011002") {
						let app = document.getElementById("app");
						app.removeAttribute("class");
					}
					if (data.uploadWay == "60471002") {
						if (data.url.toLowerCase().indexOf(".pdf") != -1) {
							// pdf文件
							this.fileType = 2;
							const loading = this.$loading({
								lock: true,
								text: "加载中...",
								spinner: "el-icon-loading",
								background: "rgba(255, 255, 255, 0.3)",
								fullscreen: false,
							});
							this.$api.industry
								.getDatumReportInfoFile({
									datumReportId: id,
									platform: 1,
								})
								.then(res => {
									this.src = this.$utils.getObjectURL(res);
									let src = pdf.createLoadingTask({
										url: this.src,
										CMapReaderFactory,
									});
									src.promise.then(pdf => {
										this.numPages = pdf.numPages;
										console.log(this.numPages);
										loading.close();
										this.times = Number.parseInt(this.numPages / 5);
										this.yushu = this.numPages % 5;

										if (this.yushu > 0) {
											this.times++;
											if (this.times == 1) {
												this.nextShow = false;
												for (let i = 1; i <= this.yushu; i++) {
													this.pageArray.push(i);
												}
											} else {
												this.nextShow = true;
												for (let i = 1; i <= 5; i++) {
													this.pageArray.push(i);
												}
											}
										} else if (this.yushu == 0) {
											if (this.times == 1) {
												this.nextShow = false;
											} else {
												this.nextShow = true;
											}
											for (let i = 1; i <= 5; i++) {
												this.pageArray.push(i);
											}
										}
										console.log(this.pageArray);
									});
								});
						} else {
							// word文档
							this.fileType = 3;

							this.$api.industry
								.getDatumReportInfoFile({
									datumReportId: id,
									platform: 1,
								})
								.then(res => {
									this.src = this.$utils.getObjectURL(res);
									this.reviewWord(data.url, this.src);
								});
						}
					} else {
						// 富文本
						this.fileType = 1;
					}
					this.detailData = data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 超出继续阅读
		next() {
			console.log(this.currentPage, this.times);
			if (this.currentPage == this.times - 1) {
				this.nextShow = false;
			}
			if (this.currentPage == this.times) {
				// this.nextShow = false;
				return;
			}
			if ((this.currentPage + 1) * 5 <= this.numPages) {
				for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
					this.pageArray.push(i);
				}
			} else {
				for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
					this.pageArray.push(i);
				}
			}
			this.currentPage++;
		},
		// 解析docx地址
		reviewWord(url, src) {
			if (url.toLowerCase().indexOf(".docx") == -1) {
				this.$nextTick(() => {
					this.wordHtml = "<p>暂不支持.doc文件预览！！！</p>";
				});
				return;
			}
			const xhr = new XMLHttpRequest();
			xhr.open("get", src, true);
			xhr.responseType = "arraybuffer";
			xhr.onload = () => {
				if (xhr.status === 200) {
					mammoth.convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) }).then(resultObject => {
						this.$nextTick(() => {
							this.wordHtml = resultObject.value;
						});
					});
				}
			};
			xhr.send();
		},
		// 获取推荐阅读
		getRecommendList(type, id) {
			if (type == 1) {
				this.$api.industry
					.getIndustryRecommended({
						industryDynamicId: id,
						platform: 1,
					})
					.then(res => {
						let data = res.data;
						data.forEach(item => {
							item.shelvesDate = item.shelvesDate.slice(0, 10);
						});
						this.recommendList = data;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (type == 2) {
				this.$api.industry
					.getPolicyPlanRecommended({
						policyPlanId: id,
						platform: 1,
					})
					.then(res => {
						let data = res.data;
						data.forEach(item => {
							if (item.dispatchDate) {
								item.dispatchDate = item.dispatchDate.slice(0, 10);
							}
							if (item.policyTag) {
								item.policyTag = item.policyTag.split(",");
							}
						});
						this.recommendList = data;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				this.$api.industry
					.getDatumReportRecommended({
						datumReportId: id,
						platform: 1,
					})
					.then(res => {
						let data = res.data;
						data.forEach(item => {
							if (item.shelvesDate) {
								item.shelvesDate = item.shelvesDate.slice(0, 10);
							}
							if (item.datumTag) {
								item.datumTag = item.datumTag.split(",");
							}
						});
						this.recommendList = data;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 点击推荐阅读列表项
		toDetail(type, item) {
			let endType = "";
			let childModuleCode = "";
			let id = "";
			if (type == 1) {
				endType = 1;
				childModuleCode = "10031003";
				id = item.industryDynamicId;
			} else if (type == 2) {
				endType = 2;
				childModuleCode = "10031002";
				id = item.policyPlanId;
			} else {
				endType = 3;
				childModuleCode = "10031004";
				id = item.datumId;
			}
			window.open(url.href, "_blank");
		},
		// 点击下载
		toDownload() {
			// this.$message.error("暂无下载权限");
			if (this.type == 1) {
				this.$api.industry.getReadAndDownload({
					industryDynamicId: this.id,
				}).then(res => {
					let data = res.data;
					if (data.download == "10011001") {
						// 有下载权限
						this.dynamicsDownload()
						// this.$utils.downloadFile(this.filePath, this.detailData.industryTitle + ".pdf");

					} else {
						// 无下载权限，提示购买下载
						this.toPay();
						// this.$refs.payModal.open = true;

						if (data.buyRead == "10011001") {
							// 购买了阅读
							this.$refs.payModal.init(2, this.detailData, "已购阅读");
						} else {
							this.$refs.payModal.init(2, this.detailData);
						}
					}
				})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (this.type == 2) {
				this.policyDownload();
			} else {
				this.dataReportDownload();
			}
		},
		toPay() {
			this.$refs.toPayOrMember.orderData = { readOrDownLoad: '60491002', industryDynamicId: this.id, reportPay: true };
			this.$refs.toPayOrMember.openDialog();
		},
		// 行业动态下载
		dynamicsDownload() {
			if (this.fileType == 1) {
				// 富文本
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadIndustryDynamicRich({
						industryId: this.id,
					})
					.then(res => {
						if (res.type == 'application/json') {
							let that = this
							let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
							reader.readAsText(res)
							console.log(res, "json");
							reader.onload = function (result) {
								let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
								loading.close();
								if (resData.code == '10001111') {
									that.$refs.toPayOrMember.openDialog()
									that.showPayBtn = true
									that.text = resData?.msg
								} else {
									that.$message.error(resData?.msg);
								}

							}
						} else {
							this.$utils.downloadFile(res, `${this.detailData.industryTitle}.pdf`);
						}
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.payVisible = true
							this.showPayBtn = false
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadIndustryDynamic({
						type:2,
						localFileUrl:this.localFileUrl,
						industryId: this.id,
					})
					.then(res => {
						if (res.type == 'application/json') {
							let that = this
							let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
							reader.readAsText(res)
							console.log(res, "json");
							reader.onload = function (result) {
								let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
								loading.close();
								if (resData.code == '10001111') {
									that.$refs.toPayOrMember.openDialog()
									that.showPayBtn = true
									that.text = resData?.msg
								} else {
									that.$message.error(resData?.msg);
								}

							}
						} else {
							this.$utils.downloadFile(res, `${this.detailData.industryTitle}.${this.fileExtension}`);
						}
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.payVisible = true
							this.showPayBtn = false
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 政策规划下载
		policyDownload() {
			if (this.fileType == 1) {
				// 富文本
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadPolicyPlanRich({
						policyPlanId: this.id,
					})
					.then(res => {
						if (res.type == 'application/json') {
							let that = this
							let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
							reader.readAsText(res)
							console.log(res, "json");
							reader.onload = function (result) {
								let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
								loading.close();
								if (resData.code == '10001111') {
									that.$refs.toPayOrMember.openDialog()
									that.showPayBtn = false
									that.text = resData?.msg
								} else {
									that.$message.error(resData?.msg);
								}

							}
						} else {
							this.$utils.downloadFile(res, `${this.detailData.policyTitle}.pdf`);
						}
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.payVisible = true
							this.showPayBtn = false
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadPolicyPlan({
						policyPlanId: this.id,
					})
					.then(res => {
						if (res.type == 'application/json') {
							let that = this
							let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
							reader.readAsText(res)
							console.log(res, "json");
							reader.onload = function (result) {
								let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
								loading.close();
								if (resData.code == '10001111') {
									that.$refs.toPayOrMember.openDialog()
									that.showPayBtn = false
									that.text = resData?.msg
								} else {
									that.$message.error(resData?.msg);
								}

							}
						} else {
							this.$utils.downloadFile(res, `${this.detailData.industryTitle}.${this.fileExtension}`);
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 资料报告下载
		dataReportDownload() {
			if (this.fileType == 1) {
				// 富文本
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadDatumReportRich({
						datumReportId: this.id,
					})
					.then(res => {
						this.$utils.downloadFile(res, `${this.detailData.datumTitle}.pdf`);
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "下载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.industry
					.downloadDatumReport({
						datumReportId: this.id,
					})
					.then(res => {
						this.$utils.downloadFile(res, `${this.detailData.industryTitle}.${this.fileExtension}`);
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
	},
	destroyed() {
		document.getElementById("app").className = "uncopy";
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
